<script setup lang="ts">
import GuestTopbar from '~/components/home/GuestTopbar.vue';
import SpellTopbar from '~/components/spell/SpellTopbar.vue';

const spellStore = useSpellsStore();
const { spell, trigger } = storeToRefs(spellStore);
const { routeName } = useRouteName();
const { status } = useAuth();
const spellId = useRouteParams('spellId');

const { error: spellError } = await useAsyncData(() =>
  spellStore.loadCompleteSpell(spellId.value as string),
);

await useAsyncData(() => spellStore.loadVersions(spellId.value as string), {
  lazy: true,
});

onBeforeMount(async () => {
  if (
    [
      'spell.bulk',
      'spell.run',
      'spell.slack',
      'spell.schedule',
      'spell.api',
      'spell',
    ].includes(routeName.value as string) &&
    trigger.value
  ) {
    await navigateTo({
      name: 'spell.history',
      params: { spellId: spell.value?.id },
      replace: true,
    });
  } else if (routeName.value === 'spell') {
    await navigateTo({
      name: 'spell.run',
      params: { spellId: spell.value?.id },
      replace: true,
    });
  }
});

watchImmediate(spellError, async () => {
  if (spellError.value) {
    throw new Error(spellError.value);
  }
});
</script>
<template>
  <div class="grow flex flex-col justify-start max-h-screen overflow-hidden">
    <SpellTopbar v-if="status === 'authenticated'" />
    <GuestTopbar v-else />
    <NuxtPage
      :keepalive="routeName !== 'editor'"
      :page-key="spellId as string"
    />
  </div>
</template>
